export interface MappingField {
  uuid: string;
  id: string;
  desc?: string;
  type: "STRING" | "DECIMAL" | "INTEGER" | "DATETIME" | "DATE" | "";
  formula?: string | null;
  filter?: string | null;
  sort?: string | null;
  aggregatorMode?: boolean | null;
  aggregatorFormula?: string | null;
  flow?: string;
  flowName?: string;
  forecastFieldType?: string;
  nonNegative?: boolean;
}

type NodeType = string;

type Node = {
  id: string;
  type: NodeType;
};

type Edge = {
  id: string;
  source: string;
  target: string;
};

export const inputNodes = [
  "flatFile",
  "knowledgeSpaceClassInput",
  "knowledgeSpaceDictionaryInput",
  "postgresInput",
  "planxFigureInput",
  "clickhouseInput",
];

export const transformationNodes = [
  "mapping",
  "rowGen",
  "join",
  "filter",
  "groupBy",
  "extrapolation",
];

export const outputNodes = [
  "targetFlatFile",
  "knowledgeSpaceClassOutput",
  "postgresOutput",
  "planxFigureOutput",
];

export const actionNodes = ["launchFlow", "rabbitmqOutput"];

export const isInput = (node: Node): boolean => {
  return inputNodes.includes(node.type);
};

export const isOutput = (node: Node): boolean => {
  return outputNodes.includes(node.type);
};

export const isTransformation = (node: Node): boolean => {
  return transformationNodes.includes(node.type);
};

export const isAction = (node: Node): boolean => {
  return actionNodes.includes(node.type);
};

export const hasOutput = (node: Node): boolean => {
  return isInput(node) || isTransformation(node);
};
