import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, message, Popconfirm, Popover, Select } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { hasOutput, MappingField } from "../utils";
import IntegrationFieldsManager from "../utils/fields";

type Props = {
  id: string;
};

type State = {
  joinType: string;
  mainTable: string;
  formula: MappingField[];
};

const initialState: State = {
  joinType: "",
  mainTable: "",
  formula: [],
};

const JoinWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.joinType = state.joinType;
          node.data.mainTable = state.mainTable;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const [mainTableOptions, setMainTableOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setFormula = setStateKeyValue("formula");
  const setJoinType = setStateKeyValue("joinType");
  const setMainTable = setStateKeyValue("mainTable");

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [outFsMap, setOutFsMap] = useState<{ [key: string]: MappingField }>({});
  const [transform, setTransform] = useState<string>();

  useEffect(() => {
    setTransform(null);
    handleEdges();
  }, [id]);

  useEffect(() => {
    if (state.formula) {
      setOutFsMap(
        produce((fields) => {
          state.formula.forEach((field) => {
            fields[field.uuid] = field;
          });
        }),
      );
    }
  }, [state.formula]);

  const [fieldsEditList, setFieldsEditList] = useState<string[]>([]);

  const handleEditFieldOn = (uuid: string) => {
    setFieldsEditList((prev) => Array.from(new Set([...prev, uuid])));
  };

  const handleDelete = (uuid: string) => {
    setFormula((prev) => prev.filter((el) => el.uuid !== uuid));
  };

  const handleEditFieldOff = (uuid: string) => {
    const el = state.formula.find((el) => el.uuid === uuid);

    if (el?.id === "") {
      message.error("Заполните идентификаторы для всех полей");
      return;
    }
    if (el?.type === "") {
      message.error("Заполните типы значений для всех полей");
      return;
    }
    setFieldsEditList((prev) => prev.filter((el) => el !== uuid));
    message.success("Поле успешно изменено");
  };

  const handleEdges = () => {
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }
    setJoinType(fNode.joinType);
    setMainTable(fNode.mainTable);
    setFormula(fNode.formula);

    const sourceEdges = edges.filter((edge) => edge.target === id);

    if (sourceEdges.length === 0) {
      const text = `Обратите внимание, что в эту ноду (${id}) не входит поток данных`;
      message.warning(text);
      return;
    }

    const tableOptions: { label: string; value: string }[] = [];
    const result: MappingField[] = [];

    for (const edge of sourceEdges) {
      const sourceNode = nodes.find((node) => node.id === edge.source);

      if (!hasOutput(sourceNode)) {
        const text = `Обратите внимание, что нода ${edge.source} не содержит выходного потока данных`;
        message.warning(text);
        continue;
      }

      if (!sourceNode.data.formula) {
        const text = `Обратите внимание, что в ноде ${edge.source} не заданы поля`;
        message.warning(text);
        continue;
      }

      tableOptions.push({ label: edge.source, value: edge.source });

      sourceNode.data.formula.forEach((f: MappingField) => {
        result.push({ ...f, flow: edge.source });
      });
    }

    setMainTableOptions(tableOptions);
    setInFs(result);
  };

  const handleMoveRight = (uuid: string) => {
    const element = inFs.find((el) => el.uuid === uuid);
    if (!element) {
      message.error(`Ошибка при попытке найти элемент`);
      return;
    }

    if (!state.formula) {
      setFormula([
        {
          ...element,
          formula: `${element.flow}.${element.id}`,
          uuid: uuidv4(),
        },
      ]);
      return;
    }

    const checkRight = state.formula.find((ele: any) => ele.id === id);
    if (checkRight) {
      message.warning(`Поле ${element.id} уже есть в исходящем потоке`);
      return;
    }

    setFormula((prev) => [
      ...prev,
      { ...element, formula: `${element.flow}.${element.id}`, uuid: uuidv4() },
    ]);
  };

  const textAreaValue = outFsMap[transform]?.formula;

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Тип джоина</div>
          <Select
            value={state.joinType}
            onChange={(e) => setJoinType(e)}
            options={[
              { value: "LEFT", label: "LEFT" },
              { value: "FULL", label: "FULL" },
              { value: "INNER", label: "INNER" },
            ]}
            style={{ width: "300px", color: "black" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Основная таблица
          </div>
          <Select
            value={state.mainTable}
            onChange={(e) => setMainTable(e)}
            options={mainTableOptions}
            style={{ width: "300px", color: "black" }}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "335px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>⤵️ Входящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "70px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Поток
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inFs?.length > 0 ? (
                    inFs.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            `${el.flow || ""}.${el.id}`,
                            state.formula,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.flow}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() => handleMoveRight(el.uuid)}
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>⤴️ Исходящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.formula?.length > 0 ? (
                    state.formula.map((f: MappingField, i: number) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {fillIndicator(f.formula)}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.id || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.id || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                R.tap(console.log),
                                makeFormulaUpdater(f.uuid, "id"),
                              )}
                              disabled={!fieldsEditList.includes(f.uuid)}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.desc || ""}>
                            <Input
                              value={outFsMap[f.uuid]?.desc || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "desc"),
                              )}
                              disabled={!fieldsEditList.includes(f.uuid)}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.type || ""}>
                            <Select
                              style={{ width: "100%" }}
                              value={outFsMap[f.uuid]?.type || ""}
                              onChange={makeFormulaUpdater(f.uuid, "type")}
                              options={__optionType}
                              disabled={!fieldsEditList.includes(f.uuid)}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() => setTransform(f.uuid)}
                              disabled={fieldsEditList.includes(f.uuid)}
                            >
                              🔎
                            </Button>
                            {!fieldsEditList.includes(f.uuid) && (
                              <Button onClick={() => handleEditFieldOn(f.uuid)}>
                                ✏️
                              </Button>
                            )}
                            {fieldsEditList.includes(f.uuid) && (
                              <Button
                                onClick={() => handleEditFieldOff(f.uuid)}
                              >
                                💾
                              </Button>
                            )}
                            <Popconfirm
                              title={`Вы действительно хотите удалить поле ${f.id}?`}
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={() => handleDelete(f.uuid)}
                            >
                              <Button>❌</Button>
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <Button
              type="dashed"
              style={{ marginTop: "5px", width: "700px" }}
              onClick={() =>
                IntegrationFieldsManager.addField(
                  state.formula,
                  setFormula,
                  setFieldsEditList,
                )
              }
            >
              Добавить поле
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {state.formula &&
              state.formula.find((el) => el.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={handleEdges}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default JoinWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];

const fillIndicator = (formula: string | null | undefined) => {
  if (!formula) {
    return "⭕";
  }
  console.log(formula, formula.indexOf("=") === -1);
  if (formula.indexOf("=") === -1) {
    if (formula.length > 0) {
      return "✅";
    } else {
      return "⭕";
    }
  } else {
    return "🔷";
  }
};
