import styled from "@emotion/styled";
import Reorder from "@mui/icons-material/Reorder";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, Popconfirm, Popover, Select } from "antd";
import { produce } from "immer";
import * as R from "ramda";
import React, { useContext, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

import { ReactComponent as AddToFormulaIcon } from "../../../../../assets/add_to_formula.svg";
import { ReactComponent as CloseSolidIcon } from "../../../../../assets/close_solid.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/edit.svg";
import { ReactComponent as LinkIcon } from "../../../../../assets/link.svg";
import { ReactComponent as NoIncomingFieldsIcon } from "../../../../../assets/no_incoming_fields.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/save.svg";
import { ReactComponent as UnLinkIcon } from "../../../../../assets/unlink.svg";

type Props = {
  id: string;
};

const SFilter = styled.div(
  () =>
    ({
      height: "60px",
      display: "flex",
      flexDirection: "column",
    }) as const,
);

const STables = styled.div(
  () =>
    ({
      width: "100%", // FIXME
      minHeight: "245px",
      height: "500px",
      overflow: "auto",
      borderRadius: "4px",
      padding: "8px",
      display: "flex",
      flexDirection: "row",
      gap: "80px",
      backgroundColor: `var(--Primary-Grey-025)`,
    }) as const,
);

const STableContainer = styled.div(
  () =>
    ({
      width: "100%", // FIXME: 528px
      height: "fit-content",
      position: "relative",
      borderRadius: "2px",
      backgroundColor: `var(--Primary-Grey-005)`,
      overflow: "hidden",

      // outline: "1px solid red", // FIXME
      // "*": {
      //   outline: "1px solid red",
      // },
    }) as const,
);

const STableTitle = styled.div(
  () =>
    ({
      height: "38px",
      padding: "10px",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "18px",
      color: `var(--Black)`,
      textTransform: "uppercase",
      backgroundColor: `var(--Primary-Grey-005)`,
      borderBottom: "1px solid var(--Primary-Grey-025)",
    }) as const,
);

const STableBottom = styled.div(() => ({
  height: "40px",
  backgroundColor: `var(--Primary-Grey-005)`,
}));

const SHeaderCell = styled(TableCell)(() => ({
  height: "30px",
  padding: "0",
  paddingLeft: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  borderBottom: "1px solid var(--Primary-Grey-025)",
  backgroundColor: `var(--Primary-Grey-010) !important`,
  lineHeight: 0,
}));

const SHeaderRow = styled(TableRow)(() => ({
  height: "30px",
  backgroundColor: `var(--Primary-Grey-010)`,
  borderBottom: "1px solid var(--Primary-Grey-025)",
}));

const SCell = styled(TableCell)(() => ({
  height: "38px",
  padding: "0px",
  paddingLeft: "10px",
  backgroundColor: `var(--White)`,
  borderBottom: "1px solid var(--Primary-Grey-025)",
  color: `var(--Primary-Grey-400)`,
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: 0,
}));

const IconContainer = styled.div(
  ({
    size,
    color,
    cursor,
  }: {
    size?: string;
    color?: string;
    cursor?: string;
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: size ? size : undefined,
    height: size ? size : undefined,
    cursor: cursor ? cursor : undefined,

    "*": {
      width: size ? "100%" : undefined,
      height: size ? "100%" : undefined,
      fill: color ? color : undefined,
    },
  }),
);

const IconButton = styled.button(({ size }: { size?: string }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  border: "unset",
  padding: "unset",
  backgroundColor: "unset",

  width: size ? size : undefined,
  height: size ? size : undefined,

  "*": {
    width: size ? "100%" : undefined,
    height: size ? "100%" : undefined,
    fill: `var(--Primary-Grey-100)`,
  },

  ":hover *": {
    fill: `var(--Primary-400)`,
  },
}));

const EmptyRows = ({ message }: { message: string }) => {
  return (
    <TableRow>
      <TableCell colSpan={100} height={"79px"} style={{ border: "unset" }}>
        <div
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <div
            style={{
              width: "fit-content",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <IconContainer color="var(--Primary-Grey-050)" size="24px">
              <NoIncomingFieldsIcon />
            </IconContainer>
            <div
              style={{
                display: "inline",
                color: "var(--Primary-Grey-100)",
              }}
            >
              {message}
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

type State = {
  filter: string;
  limit?: string | undefined;
  formula: MappingField[];
};

const initialState: State = {
  filter: "",
  limit: undefined,
  formula: [],
};

const MappingWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.formula = state.formula;
          node.data.filter = state.filter;
          node.data.limit = state.limit;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setFilter = setStateKeyValue("filter");
  const setFormula = setStateKeyValue("formula");
  const setLimit = setStateKeyValue("limit");

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [outFsMap, setOutFsMap] = useState<Record<string, MappingField>>({});
  const [transform, setTransform] = useState<string>();

  const [fieldsEditList, setFsEditList] = useState<string[]>([]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setFormula((fs: any) => {
      const temp = [...fs];
      const d = temp[result.destination!.index];
      temp[result.destination!.index] = temp[result.source.index];
      temp[result.source.index] = d;
      return temp;
    });
  };

  useEffect(() => {
    setTransform(undefined);

    const node: any = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.formula = node.data.formula ?? initialState.formula;
          state.filter = node.data.filter ?? initialState.filter;
          state.limit = node.data.limit ?? initialState.limit;
        }),
      );
    }

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id, nodes, edges]);

  useEffect(() => {
    if (state.formula.length > 0) {
      setOutFsMap(
        produce((mapping) => {
          state.formula.forEach((f) => {
            mapping[f.uuid] = f;
          });
        }),
      );
    }
  }, [state.formula]);

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const createRFieldUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  const inputTable = (
    <STableContainer>
      <STableTitle>Входящий поток</STableTitle>
      <div>
        <Table stickyHeader>
          <TableHead>
            <SHeaderRow>
              <SHeaderCell>ID</SHeaderCell>
              <SHeaderCell>Описание</SHeaderCell>
              <SHeaderCell>Тип</SHeaderCell>
              <SHeaderCell>
                <IconContainer
                  size="18px"
                  color="var(--Primary-Grey-100)"
                  cursor="pointer"
                  onClick={() =>
                    IntegrationFieldsManager.moveAllFieldsToRight({
                      sourceFields: inFs,
                      setTargetFields: setFormula,
                    })
                  }
                >
                  <LinkIcon />
                </IconContainer>
              </SHeaderCell>
            </SHeaderRow>
          </TableHead>
          <TableBody>
            {inFs.length > 0 ? (
              inFs.map((f, i) => (
                <TableRow key={i}>
                  <SCell>{f.id}</SCell>
                  <SCell>{f.desc}</SCell>
                  <SCell>{f.type}</SCell>
                  <SCell width="38px">
                    <IconContainer
                      size="18px"
                      color="var(--Primary-Grey-100)"
                      cursor="pointer"
                      onClick={() =>
                        IntegrationFieldsManager.moveFieldToTarget(
                          f.id,
                          inFs,
                          setFormula,
                        )
                      }
                    >
                      {IntegrationFieldsManager.getSourceFieldStatus(
                        f.id,
                        state.formula,
                      ) ? (
                        <LinkIcon />
                      ) : (
                        <UnLinkIcon />
                      )}
                    </IconContainer>
                  </SCell>
                </TableRow>
              ))
            ) : (
              <EmptyRows message="Нет входящих полей" />
            )}
          </TableBody>
        </Table>
      </div>
      {inFs.length > 0 && <STableBottom />}
    </STableContainer>
  );

  const outputTable = (
    <STableContainer>
      <STableTitle>Исходящий поток</STableTitle>
      <div>
        <Table stickyHeader>
          <TableHead>
            <SHeaderRow>
              <SHeaderCell />
              <SHeaderCell>ID</SHeaderCell>
              <SHeaderCell>Описание</SHeaderCell>
              <SHeaderCell>Тип</SHeaderCell>
              <SHeaderCell>Действие</SHeaderCell>
              <SHeaderCell>Сортировка</SHeaderCell>
              <SHeaderCell />
            </SHeaderRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(dp: DroppableProvided) => (
                <TableBody ref={dp.innerRef} {...dp.droppableProps}>
                  {state.formula.length > 0 ? (
                    state.formula.map((f, i) => (
                      <Draggable key={f.uuid} draggableId={f.uuid} index={i}>
                        {(dp) => (
                          <TableRow ref={dp.innerRef} {...dp.draggableProps}>
                            <SCell width="38px">
                              <IconContainer
                                size="18px"
                                color="var(--Primary-Grey-100)"
                              >
                                {f.formula && f.formula.length > 0 ? (
                                  <LinkIcon />
                                ) : (
                                  <UnLinkIcon />
                                )}
                              </IconContainer>
                            </SCell>
                            <SCell>
                              <Popover content={outFsMap[f.uuid]?.id || ""}>
                                <Input
                                  variant={
                                    fieldsEditList.includes(f.uuid)
                                      ? "outlined"
                                      : "borderless"
                                  }
                                  disabled={!fieldsEditList.includes(f.uuid)}
                                  value={outFsMap[f.uuid]?.id || ""}
                                  onChange={R.pipe(
                                    R.path(["target", "value"]),
                                    createRFieldUpdater(f.uuid, "id"),
                                  )}
                                />
                              </Popover>
                            </SCell>
                            <SCell>
                              <Popover content={outFsMap[f.uuid]?.desc || ""}>
                                <Input
                                  variant={
                                    fieldsEditList.includes(f.uuid)
                                      ? "outlined"
                                      : "borderless"
                                  }
                                  disabled={!fieldsEditList.includes(f.uuid)}
                                  value={outFsMap[f.uuid]?.desc || ""}
                                  onChange={R.pipe(
                                    R.path(["target", "value"]),
                                    createRFieldUpdater(f.uuid, "desc"),
                                  )}
                                />
                              </Popover>
                            </SCell>
                            <SCell>
                              <Popover content={outFsMap[f.uuid]?.type || ""}>
                                <Select
                                  variant={
                                    fieldsEditList.includes(f.uuid)
                                      ? "outlined"
                                      : "borderless"
                                  }
                                  popupMatchSelectWidth={110}
                                  style={{ width: "110px" }}
                                  disabled={!fieldsEditList.includes(f.uuid)}
                                  value={outFsMap[f.uuid]?.type || ""}
                                  onChange={createRFieldUpdater(f.uuid, "type")}
                                  options={OPTIONS_TYPE}
                                />
                              </Popover>
                            </SCell>
                            <SCell>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "8px",
                                  padding: "10px",
                                  alignItems: "center",
                                }}
                              >
                                {!fieldsEditList.includes(f.uuid) && (
                                  <IconButton
                                    size="18px"
                                    onClick={() =>
                                      IntegrationFieldsManager.enableFieldEditMode(
                                        f.uuid,
                                        setFsEditList,
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                {fieldsEditList.includes(f.uuid) && (
                                  <IconButton
                                    size="18px"
                                    onClick={() =>
                                      IntegrationFieldsManager.disableFieldEditMode(
                                        f.uuid,
                                        state.formula,
                                        setFsEditList,
                                      )
                                    }
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  size="18px"
                                  disabled={fieldsEditList.includes(f.uuid)}
                                  onClick={() => setTransform(f.uuid)}
                                >
                                  <AddToFormulaIcon />
                                </IconButton>
                                <Popconfirm
                                  title={`Вы действительно хотите удалить поле ${f.id}?`}
                                  okText="Да"
                                  cancelText="Нет"
                                  onConfirm={() =>
                                    IntegrationFieldsManager.removeField(
                                      f.uuid,
                                      setFormula,
                                    )
                                  }
                                >
                                  <IconButton size="18px">
                                    <CloseSolidIcon />
                                  </IconButton>
                                </Popconfirm>
                              </div>
                            </SCell>
                            <SCell>
                              <Select
                                variant={
                                  fieldsEditList.includes(f.uuid)
                                    ? "outlined"
                                    : "borderless"
                                }
                                style={{ width: "86px" }}
                                disabled={!fieldsEditList.includes(f.uuid)}
                                value={outFsMap[f.uuid]?.sort}
                                onChange={createRFieldUpdater(f.uuid, "sort")}
                                options={OPTIONS_SORT}
                                allowClear={true}
                              />
                            </SCell>
                            <SCell>
                              <div {...dp.dragHandleProps}>
                                <Reorder />
                              </div>
                            </SCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <EmptyRows message="Нет исходящих полей" />
                  )}
                  {dp.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </div>
      <Button
        type="dashed"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={() =>
          IntegrationFieldsManager.addField(
            state.formula,
            setFormula,
            setFsEditList,
          )
        }
      >
        Добавить поле
      </Button>
    </STableContainer>
  );

  const filterComponent = (
    <SFilter>
      <div style={{ fontWeight: "bold" }}>Фильтр</div>
      <Input.TextArea
        style={{ color: "black" }}
        value={state.filter}
        onChange={R.pipe(R.path(["target", "value"]), setFilter)}
        allowClear={true}
      />
    </SFilter>
  );

  const limitComponent = (
    <SFilter>
      <div style={{ fontWeight: "bold" }}>Лимит</div>
      <Input.TextArea
        style={{ color: "black" }}
        value={R.defaultTo(null)(state.limit)}
        onChange={R.pipe(
          R.path<string>(["target", "value"]),
          R.when(R.test(/^\s*$/g), () => undefined),
          R.defaultTo(undefined),
          setLimit,
        )}
        allowClear={true}
      />
    </SFilter>
  );

  return (
    <SContent>
      {filterComponent}
      {limitComponent}

      <STables>
        {inputTable}
        {outputTable}
      </STables>

      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <span>
          Параметр мэппинга:{" "}
          {state.formula.find((f) => f.uuid === transform)?.id}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            height: "100px",
          }}
        >
          <Input.TextArea
            size="large"
            style={{ height: "100px", resize: "none" }}
            disabled={!transform}
            value={textAreaValue}
            onChange={onMappingParamChange}
          />
          <Button
            style={{ width: "100px", height: "100px", fontSize: "36px" }}
            onClick={() => console.log(nodes)}
          >
            👁
          </Button>
        </div>
      </div>
    </SContent>
  );
};

export default MappingWindow;

const OPTIONS_SORT = [
  {
    label: "ASC",
    value: "ASC",
  },
  {
    label: "DESC",
    value: "DESC",
  },
];

const OPTIONS_TYPE = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
