import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, Popover, Select } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Props = {
  id: string;
};

type State = {
  formula: MappingField[];
};

const initialState: State = {
  formula: [],
};

const FilterWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.formula = state.formula;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setFormula = setStateKeyValue("formula");

  const [outFsMap, setOutFsMap] = useState<{ [key: string]: MappingField }>({});
  const [transform, setTransform] = useState<string>();

  useEffect(() => {
    setTransform(undefined);

    const node = nodes.find((node) => node.id === id)!;

    const fields: any[] = [];

    const leftEdge = edges.find((edge) => edge.target === id);
    if (leftEdge) {
      const leftNode = nodes.find((node) => node.id === leftEdge.source)!;

      ((leftNode.data.formula as any[]) ?? []).forEach((field) => {
        const findF = [...((node.data.formula as any[]) ?? [])].find(
          (f) => f.id === field.id,
        );
        const newF = findF ? { ...field, ...findF } : field;
        fields.push(newF);
      });
    }

    setFormula(fields);
  }, [id]);

  useEffect(() => {
    if (state.formula && state.formula.length > 0) {
      setOutFsMap(
        produce((fields) => {
          state.formula.forEach((field) => {
            fields[field.uuid] = field;
          });
        }),
      );
    }
  }, [state.formula]);

  const textAreaValue = outFsMap[transform]?.filter || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.filter = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "425px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Входящий/Исходящий поток
            </span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Действие
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.formula?.length > 0 ? (
                  state.formula.map((f: MappingField, i: number) => (
                    <TableRow key={i}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {f.filter && f.filter.length > 0 ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.id || ""}>
                          <Input
                            value={outFsMap[f.uuid]?.id || ""}
                            onChange={R.pipe(
                              R.path(["target", "value"]),
                              makeFormulaUpdater(f.uuid, "id"),
                            )}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.desc || ""}>
                          <Input
                            value={outFsMap[f.uuid]?.desc || ""}
                            onChange={R.pipe(
                              R.path(["target", "value"]),
                              makeFormulaUpdater(f.uuid, "desc"),
                            )}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.type || ""}>
                          <Select
                            style={{ width: "100%" }}
                            value={outFsMap[f.uuid]?.type || ""}
                            onChange={makeFormulaUpdater(f.uuid, "type")}
                            options={__optionType}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Button onClick={() => setTransform(f.uuid)}>🔎</Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет входящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр фильтра:{" "}
            {state.formula &&
              state.formula.find((f) => f.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log(nodes)}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default FilterWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
